@font-face {
    font-family: goodtimesrg;
    src: url(good\ times\ rg.otf);
  }

  @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);

  * {
    font-family: Open Sans;
  }

.rzBody{
    background-image: url('./img/homepic3.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 1000px;
    position: relative;
}

.body_titel{
    font-family: goodtimesrg;
    margin-bottom: 4%;
}

.subtitle-body{
  font-family: goodtimesrg;
}
  
.body-content{ 
  padding-top: 5%; 
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0, 0.6);
}

.oeffnungdiv{
  margin-top: 30%;
}
    
.texttitel {
  position: relative;
  z-index: 1;
  color: white;
  font-weight: 500;
  font-size: 17px;
  padding-left: 12%;
  padding-right: 10%;
}

.backback{
  width: 100%;
  margin-top: -5%;
  height: 562px;
  position: absolute;
}

.h3-oeffnung{
  font-family: goodtimesrg;
  text-align: center;
  color: white;
  margin-bottom: 2%;
}

.m-market-info-all{
  border-color: black;
  border-width: 21px;
  border-style: solid;
  color: white;
  font-weight: 700;
  font-size: larger;
  max-width: 100%;
  margin: 4% auto;
  background-color: rgba(0,0,0, 0.5);
  border-radius: 5px;
  padding: 20px 20px 20px 0px;
  border-width: 0px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  margin-top: -15%;
}

    
li {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}
    
.time-span{
  margin-left: 4%;
}
          

/* Mobile-Stile */
@media only screen and (max-width: 480px) {
  .body-content{ 
    padding-top: 10%; 
    backdrop-filter: blur(5px);
}

.texttitel{
  font-size: 13px;
  padding-left: 4%;
  padding-right: 3%;
}

.backback{
  margin-top: -10%;
}

.oeffnungdiv {
  margin-top: 80%;
}

.rzBody{
  height: 925px;
}

.h3-oeffnung{
  margin-top: 1%;
}

.m-market-info-all{
    font-size: medium;
}
}

