@font-face {
  font-family: goodtimesrg;
  src: url(good\ times\ rg.otf);
}

body, html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.hole-gallery{
    display: grid;
    place-items: center;
}

.gallery {
  --s: 174px;
  --g: 12px;
  --f: 1.5;
  display: grid;
  width: 731px;
  aspect-ratio: 1;
}

.gallery > img {
  width: 0;
  height: 0;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  cursor: pointer;
  filter: grayscale(80%);
  transition: .35s linear;
}

.gallery img:hover{
  filter: grayscale(0);
  width:  calc(var(--s)*var(--f));
  height: calc(var(--s)*var(--f));
}

.footer{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;
}

/* Mobile-Stile */
@media only screen and (max-width: 480px) {
.alles{
  height: 100px;
}
}