@font-face {
  font-family: goodtimesrg;
  src: url(good\ times\ rg.otf);
}

/* Mobile-Stile */
@media only screen and (max-width: 480px) {
  .container {
      max-width: 450px;
      margin-left: 1%;
  }
}

.profile-image{
 background-image: url(img/backgroundHomeFarb\ -\ Kopie.jpeg);
 background-repeat: no-repeat;
 background-size: cover;
 height: 220px;
 width: 100%;
 margin-bottom: 4%;
}

.button,
a.button,
input[type=submit]:not([class*=hs-button]),
input[type=button] {
  color: #fff;
  background: #1c5f99;
  border: 1px solid #1c5f99;
  display: inline-block;
  text-decoration: none;
  padding: 1rem 2rem;
  margin: 2rem 0rem 1.7rem;;
  border-radius: 40px;
  line-height: 1.2;
  transition: 0.3s;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 700;
  font-size: 13px;
}
.button:hover,
a.button:hover,
input[type=submit]:not([class*=hs-button]):hover,
input[type=button]:hover {
  cursor: pointer;
  color: #1c5f99;
  background: transparent;
  border-color: #1c5f99;
}

.about-us{
  margin-bottom: 4%;
}
