@font-face {
  font-family: goodtimesrg;
  src: url(good\ times\ rg.otf);
}

.logo{
  height: 59px;
  width: 165px;
  margin-left: 85px;
  margin-top: -8px;
}

.navbar-ul {
list-style: none;
color: #07649d;
display: flex;
font-size: 20px;
}

.nav-link{
  margin-right: 48px;
  margin-top: 11px;
  font-family: goodtimesrg;
}

.nav-styling{
  text-decoration: none;
  color: #07649d;
  font-size: large;
  font-family: goodtimesrg;
}

.burger-menu{
 margin-left: 0px;
 position: absolute;
}

.offcanvas.offcanvas-end {
  top: 0;
  left: 0 !important;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%) !important;
}




