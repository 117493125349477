@font-face {
    font-family: goodtimesrg;
    src: url(good\ times\ rg.otf);
  }

  .kontaktAll{
    background-image: url('img/backgroundHome.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 650px;
  }

  .formular {
    max-width: 909px;
    margin: 4% auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  }

  label {
    font-weight: bold;
  }

  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 10px;
  }

  textarea {
    height: 150px;
  }

  input[type="submit"] {
    background-color: #07649d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }

  input[type="submit"]:hover {
    background-color: #0c86d2;
  }

.h3-kontakt{
    text-align: center;
    color: black;
    font-family: goodtimesrg;
}